import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import SubmissionModal from './SubmissionModal';
import styles from './Home.module.css';

Modal.setAppElement('#root');

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState('');
  const [email, setEmail] = useState('');
  const [tsbUid, setTsbUid] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLaunchingSoonModalOpen, setIsLaunchingSoonModalOpen] = useState(false);
  const isEventOver = true; // Set this to true to disable all game buttons

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setEmail(user.email);
      setTsbUid(user.tsb_uid);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmitScoreClick = (game) => {
    const storedUser = localStorage.getItem('user');
    if (!storedUser) {
      navigate('/login');
    } else {
      setSelectedGame(game);
      setIsModalOpen(true);
    }
  };

  const handlePlayClick = (game) => {
    if (game === 'Trendy Boulder') {
      window.location.href = 'https://www.sandbox.game/en/experiences/123/7639a34d-c342-410b-a6e4-84af88f0569d/page/';
    } else if (game === 'Trendy Match') {
      window.location.href = 'https://www.sandbox.game/en/experiences/trendy-match/4aed27d1-62db-44cb-b130-ff5ee4d8f57b/page/';
    } else {
      window.location.href = `https://www.sandbox.game/en/experiences/${game}/020d472c-48dc-4dbe-9729-995a90b90e4d/page/`;
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeLaunchingSoonModal = () => {
    setIsLaunchingSoonModalOpen(false);
  };

  return (
    <div className={styles.homeContainer}>
      <div className={styles.welcomeContainer}>
        <div className={styles.mainMessage}>
          We build fun games on <br className="mobileLineBreak" /> <span className={styles.sandboxGame}>The Sandbox Game<span className="desktopLineBreak"></span></span>
        </div>
        {!isMobile && (
          <div className={styles.subMessage}>
            In the Last Builders Challenge, our game, Trendy Factory, placed on top
            <br className={styles.mobileBreak} />and helped 54 Trendy Nerd players earn a total of <span className={styles.sandAmount}>32,346.23 SAND</span>
          </div>
        )}
      </div>
      <div className={styles.newBlock}>
        <div className={styles.imageContainer}>
          <img src="/images/pepperbell.png" alt="Tortuga: The Pepperbell Legend" className={styles.gameImageWide} />
        </div>
        <div className={styles.blockTitle}>
          Tortuga: The Pepperbell Legend
        </div>
        {!isMobile && (
          <div className={styles.blockSubtitle}>
            A single pepper seed transforms a clumsy local hero, propelling him into the adventure of a lifetime.
          </div>
        )}
        <div className={styles.newBlockButtonContainer}>
          <button className={`${styles.newBlockButton} ${styles.playButtonGrey}`} disabled>Play</button>
          <button className={`${styles.newBlockButton} ${styles.submitButtonGrey}`} disabled>Submit</button>
        </div>
      </div>
      <div className={styles.newBlock}>
        <div className={styles.imageContainer}>
          <img src="/images/Trendy_Match.png" alt="Trendy Match" className={`${styles.gameImageWide} ${styles.trendyMatchImage}`} />
        </div>
        <div className={styles.blockTitle}>
          Trendy Match <span className={styles.finishedLabel}>[FINISHED]</span>
        </div>
        {!isMobile && (
          <div className={styles.blockSubtitle}>
            Match food with animal icons to survive as long as you can.<br />
            25,000 SAND [Top 15 players]
          </div>
        )}
        <div className={styles.newBlockButtonContainer}>
          <button className={`${styles.newBlockButton} ${styles.playButtonGrey}`} disabled={isEventOver}>Play</button>
          <button className={`${styles.newBlockButton} ${styles.submitButtonGrey}`} disabled={isEventOver}>Submit</button>
        </div>
      </div>
      <div className={styles.newBlock}>
        <div className={styles.imageContainer}>
          <img src="/images/FactoryBg.png" alt="Trendy Factory" className={`${styles.gameImageWide} ${styles.trendyFactoryImage}`} />
        </div>
        <div className={styles.blockTitle}>
          Trendy Factory: New Day <span className={styles.finishedLabel}>[FINISHED]</span>
        </div>
        {!isMobile && (
          <div className={styles.blockSubtitle}>
            Build and maintain clothing factory for as long as you can.<br />
            Share 40,000 Sand Pool
          </div>
        )}
        <div className={styles.newBlockButtonContainer}>
          <button className={`${styles.newBlockButton} ${styles.playButtonGrey}`} disabled={isEventOver}>Play</button>
          <button className={`${styles.newBlockButton} ${styles.submitButtonGrey}`} disabled={isEventOver}>Submit</button>
          <button className={`${styles.newBlockButton} ${styles.submitButtonGrey}`} disabled={isEventOver}>Event</button>
        </div>
      </div>
      <SubmissionModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        game={selectedGame}
        email={email}
        tsbUid={tsbUid}
      />
      <Modal
        isOpen={isLaunchingSoonModalOpen}
        onRequestClose={closeLaunchingSoonModal}
        className={styles.launchingSoonModal}
        overlayClassName={styles.overlay}
        shouldCloseOnOverlayClick={true}
      >
        <h2>Trendy Boulder Launching Soon</h2>
        <p>Stay tuned! Trendy Boulder will be launching soon.</p>
      </Modal>
    </div>
  );
};

export default Home;
